const list=[
    {'number':'01', 'color': [2,3], 'style': [1,2], 'space':[6],'src': require('../img/3d/01.jpg'),'id':'1','type':'1', 'switch':'0'},
    {'number':'02', 'color': [1,4], 'style': [5,3], 'space':[1],'src': require('../img/3d/02.jpg'),'id':'2', 'type':'1', 'switch':'0'},
    {'number':'03', 'color': [1,4], 'style': [5,3], 'space': [1],'src': require('../img/3d/03.jpg'),'id':'3', 'type':'1', 'switch':'0'},
    {'number':'04', 'color': [1,4], 'style': [5,3], 'space': [4],'src': require('../img/3d/04.jpg'),'id':'4', 'type':'1','switch':'0'},
    {'number':'05', 'color': [1,4], 'style': [5,4], 'space': [4],'src': require('../img/3d/05.jpg'),'id':'5', 'type':'1','switch':'1'},
    {'number':'06', 'color': [1,5], 'style': [5,4], 'space': [4],'src': require('../img/3d/06.jpg'),'id':'6', 'type':'1', 'switch':'2'},
    {'number':'07', 'color': [1,5], 'style': [5], 'space': [5],'src': require('../img/3d/07.jpg'),'id':'7', 'type':'1', 'switch':'0'},
    {'number':'08', 'color': [1,6], 'style': [5,4], 'space': [1],'src': require('../img/3d/08.jpg'),'id':'8', 'type':'1', 'switch':'0'},
     {'number':'1', 'color': [1,3], 'style': [8], 'texture':'1','space': [5,8],'src': require('../img/map/1.jpg'),'id':'1', 'type':'2', 'switch':'1'},
  {'number':'2', 'color': [2,7,3], 'style': [8], 'texture':'1','space': [5,8],'src': require('../img/map/2.jpg'),'id':'2', 'type':'2', 'switch':'2'},
  {'number':'3', 'color': [1,3], 'style': [5], 'texture':'1','space': [5,7],'src': require('../img/map/3.jpg'),'id':'3', 'type':'2', 'switch':'0'},
  {'number':'4', 'color': [1], 'style': [7], 'texture':'1','space': [3],'src': require('../img/map/4.jpg'),'id':'4', 'type':'2', 'switch':'0'},
//   {'number':'5', 'color': [2,1], 'style': [7], 'texture':'1','space': [3],'src': require('../img/map/5.jpg'),'id':'5', 'type':'2', 'switch':'0'},
  {'number':'6', 'color': [1,4,3], 'style': [2,5], 'texture':'1','space': [4,8],'src': require('../img/map/6.jpg'),'id':'6', 'type':'2', 'switch':'0'},
  {'number':'7', 'color': [8,4], 'style': [3,2], 'texture':'1','space': [4,8],'src': require('../img/map/7.jpg'),'id':'7', 'type':'2', 'switch':'0'},
  {'number':'8', 'color': [2,8], 'style': [9,5], 'texture':'1','space': [4,6],'src': require('../img/map/8.jpg'),'id':'8', 'type':'2','switch':'0'},
  {'number':'9', 'color': [1,3,4], 'style': [1,2], 'texture':'1','space': [4,8],'src': require('../img/map/9.jpg'),'id':'9', 'type':'2', 'switch':'0'},
  {'number':'10', 'color': [1], 'style': [5,6], 'texture':'2','space': [5],'src': require('../img/map/10.jpg'),'id':'10', 'type':'2','switch':'0'},
  {'number':'11', 'color': [8,2], 'style': [5], 'texture':'3','space': [2],'src': require('../img/map/11.jpg'),'id':'11', 'type':'2','switch':'0'},
  {'number':'12', 'color': [1], 'style': [5,6], 'texture':'3','space': [5],'src': require('../img/map/12.jpg'),'id':'12', 'type':'2','switch':'0'},
  {'number':'13', 'color': [1,7], 'style': [5,6], 'texture':'2','space': [5],'src': require('../img/map/13.jpg'),'id':'13', 'type':'2', 'switch':'0'},
  {'number':'14', 'color': [1,9], 'style': [5], 'texture':'1','space': [5,6],'src': require('../img/map/14.jpg'),'id':'14', 'type':'2', 'switch':'1'},
  {'number':'15', 'color':[1,9], 'style': [5,8], 'texture':'1','space':[5,6],'src': require('../img/map/15.jpg'),'id':'15', 'type':'2', 'switch':'2'},
  {'number':'16', 'color': [8,10], 'style': [5], 'texture':'2','space': [5],'src': require('../img/map/16.jpg'),'id':'16', 'type':'2', 'switch':'1'},
  {'number':'17', 'color': [4,10], 'style': [5], 'texture':'3','space': [5],'src': require('../img/map/17.jpg'),'id':'17', 'type':'2', 'switch':'2'},

]
export default list;