



// 环境：1深色 2浅色 3绿色 4蓝色 5黄绿 6蓝橙 7棕色 8灰色 9单色 10黑色
// 风格：1青绿山水 2中国风   3写实  4-low-poly   5科技  6扁平 7建筑绘画 8日式写意  9太空
// 空间：1 城市建筑 2 2.5D模型 3- 2D/3D混合 4- 3D模型  5 2D模型 6 地形图 7- 卫星图   8- 着色

const arrData=[
    {'number':'01', 'color': [2,3], 'style': [1,2], 'space':[6],'src': require('../img/3d/01.jpg'),'id':'1','type':'1', 'switch':'0'},
    {'number':'02', 'color': [1,4], 'style': [5,3], 'space':[1],'src': require('../img/3d/02.jpg'),'id':'2', 'type':'1', 'switch':'0'},
    {'number':'03', 'color': [1,4], 'style': [5,3], 'space': [1],'src': require('../img/3d/03.jpg'),'id':'3', 'type':'1', 'switch':'0'},
    {'number':'04', 'color': [1,4], 'style': [5,3], 'space': [4],'src': require('../img/3d/04.jpg'),'id':'4', 'type':'1','switch':'0'},
    {'number':'05', 'color': [1,4], 'style': [5,4], 'space': [4],'src': require('../img/3d/05.jpg'),'id':'5', 'type':'1','switch':'1'},
    {'number':'06', 'color': [1,5], 'style': [5,4], 'space': [4],'src': require('../img/3d/06.jpg'),'id':'6', 'type':'1', 'switch':'2'},
    {'number':'07', 'color': [1,5], 'style': [5], 'space': [5],'src': require('../img/3d/07.jpg'),'id':'7', 'type':'1', 'switch':'0'},
    {'number':'08', 'color': [1,6], 'style': [5,4], 'space': [1],'src': require('../img/3d/08.jpg'),'id':'8', 'type':'1', 'switch':'0'},
    {'number':'09', 'color': [1,3,10], 'style': [5], 'space': [1],'src': require('../img/3d/09.png'),'id':'9', 'type':'1', 'switch':'0'},
    {'number':'10', 'color': [1,4], 'style': [3], 'space': [4],'src': require('../img/3d/10.png'),'id':'10', 'type':'1', 'switch':'0'},
    {'number':'11', 'color': [1,3,10], 'style': [5], 'space': [4],'src': require('../img/3d/11.png'),'id':'11', 'type':'1', 'switch':'0'},
    {'number':'12', 'color': [7,2], 'style': [5], 'space': [4,6],'src': require('../img/3d/12.png'),'id':'12', 'type':'1', 'switch':'0'},
]
export default arrData;



// const arrData=[
//     {'number':'01', 'color':'1', 'style':'1', 'texture':'1','src': require('../img/3d/01.jpg'),'id':'1','type':'1', 'switch':'0'},
//     {'number':'02', 'color':'2', 'style':'1', 'texture':'1','src': require('../img/3d/02.jpg'),'id':'2', 'type':'1', 'switch':'0'},
//     {'number':'03', 'color':'1', 'style':'1', 'texture':'1','src': require('../img/3d/03.jpg'),'id':'3', 'type':'1', 'switch':'0'},
//     {'number':'04', 'color':'2', 'style':'1', 'texture':'1','src': require('../img/3d/04.jpg'),'id':'4', 'type':'1','switch':'0'},
//     {'number':'05', 'color':'1', 'style':'2', 'texture':'2','src': require('../img/3d/05.jpg'),'id':'5', 'type':'1','switch':'1'},
//     {'number':'06', 'color':'2', 'style':'2', 'texture':'2','src': require('../img/3d/06.jpg'),'id':'6', 'type':'1', 'switch':'2'},
//     {'number':'07', 'color':'2', 'style':'2', 'texture':'2','src': require('../img/3d/07.jpg'),'id':'7', 'type':'1', 'switch':'0'},
//     {'number':'08', 'color':'2', 'style':'2', 'texture':'2','src': require('../img/3d/08.jpg'),'id':'8', 'type':'1', 'switch':'0'},
// ]
// export default arrData;