<template>
    <div class="picture">
        <!-- 右侧控件 -->
        <div class="right">
            <div class="div1 box around center hand" @click="next()">
               <span class="right1"></span>
            </div>
            <div class="div2 box around center hand" @click="up">
                <span class="right2"></span>
            </div>
            <div class="div3 box center around">
                <span class="fs24 white">{{number1}}</span>
                <span class="fs24 white">/</span>
                <span class="fs14 white">{{lenght}}</span>
            </div>
        </div>

        <p class="fs40 white textL" style="margin-bottom:56px">三维/地图场景预览</p>

        <ul class="box justify wrap"  style="width:100%; height:100%">
            <li v-for="(item, index) in arr" :key="index" style='width:30%;'>
                <div class="textL box center mb20">
                    <span class="tiao"></span>
                    <span class="fs18 white text">
                        <span v-for="(item1,index) in item.style" :key='index'>
                            {{item1 | styleSelect}}
                        </span>
                         <!-- <span v-for="(item2,index) in item.color" :key='index'>
                            {{item2 | colorSelect}}
                        </span> -->
                    </span>
                    <!-- <span class="fs18 white text" v-else>
                         <span v-for="(item3,index3) in item.style" :key='index3'>
                            {{item3 | styleSelect}}
                        </span>
                           <span v-for="(item4,index4) in item.color" :key='index4'>
                            {{item4 | colorSelect}}
                        </span>
                    </span> -->
                </div>
                <div>
                    <img class="img" :src="item.src" alt="">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    
    import arrData from '../assets/js/arrData.js'
    import mapData from '../assets/js/mapData.js'
    import listData from '../assets/js/list.js'

    export default {
        data() {
            return {
                arr: [],
                list:[],
                n1:0,
                n2:6,
                n: 0,

                flag: 1,
                arrData: arrData,
                mapData: mapData,
                number: 1,
                number1:'1',
                lenght: '',
                src:''

            }
        },
        created () {

            
        },
        mounted () {
            console.log('9999999999999999999');
            // mapData.splice(4,1);
            this.list =  listData
            this.arr  = this.list.slice(0, 6);
            console.log('arr', this.arr);

            var lenght =  Math.round(this.list.length/6);
            this.lenght = lenght.toString().padStart(2, '0');

            this.number1 =  this.number1.toString().padStart(2, '0');


        },
        methods: {
            next() {
                var lenght =  Math.round(this.list.length/6);
                if(this.number == lenght){
                    return
                }

                this.number = this.number + 1;
                this.number1 = this.number;
                this.number1 =  this.number1.toString().padStart(2, '0');
                // var arr = this.arrData.concat(this.mapData); 
                var arr = listData;

                this.n = 1 + this.n;
                // console.log(111,this.n*6);  //6
                // console.log(222,(this.n+1)*6);  //12
                this.arr = arr.slice(this.n*6,(this.n+1)*6);

                console.log('arr', this.arr);

            },
            up(){
                if(this.number1 == 1){
                    return;
                }
                this.number = this.number - 1;
                this.number1 = this.number;
                this.number1 =  this.number1.toString().padStart(2, '0');
                // var arr = this.arrData.concat(this.mapData); 
                var arr = listData;

                this.n = this.n-1;
                console.log(111,this.n*6);    //0
                
                console.log(222,(this.n+1)*6);  //6
                this.arr = arr.slice(this.n*6,(this.n+1)*6);


            }
        },
    }
</script>

<style lang="scss" scoped>
    .picture{
        width: 100%;
        height: 100%;
        padding: 95px 123px;
        background-color: rgb(27 50 68 / 90%);
    }
    ul li{
        float: left;
    }

    .img{
        width: 100%;
    }
    .tiao{
        display: inline-block;
        background-color: #fff;
        width: 5px;
        height: 22px;
        margin-right: 5px;
    }
    .text{
        // line-height: 22px;
    }

    //左右控件
    .right{
        position: absolute;
        right: 0;
        top: 40%;
        margin-right: 20px;
        // background-color: rgba(35,71,99, 1);
    }
    .div1{
        width: 58px;
        height: 74px;
        background-color: rgba(35,71,99, 0.6);
    }
    .right1{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconRight1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
   .div1:hover .right1{
        background: url('../assets/images/iconRight2.png') no-repeat;
    }
     .div2{
        width: 58px;
        height: 74px;
        background-color: rgba(35,71,99, 0.6);
    }
    .right2{
        display: inline-block;
        width:11px;
        height:23px;
        background: url('../assets/images/iconLeft1.png') no-repeat;
        background-size:100% 100%;
        align-items: middle;
    }
    .div2:hover .right2{
        background: url('../assets/images/iconLeft2.png') no-repeat;
    }


    .div3{
        width: 58px;
        height: 38px;
        background-color: rgba(29,69,99,0.32);
    }


</style>